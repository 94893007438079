<template>
  <div>
    <div class="">
      <van-nav-bar :title="$t('我的理财')"  placeholder fixed left-arrow @click-left="$router.push({ path: '/invest' })" />
    </div>
    <div class="main" style="height: calc(100vh - 100px)">
      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit" >
        <div v-for = "(item,index) in dataList" :key="index" >
          <div class="card">
            <div class="info">
              <div>{{ $t('时间') }}:{{ item.create_time | dateformat }}</div>
              <div>{{ $t('名称') }}:{{ item.title }}</div>
              <div>{{ $t('金额') }}:{{ item.money }}</div>
            </div>
            <div class="log">
              <van-steps direction="vertical" :active="0" active-color="#0153C1">
                <van-step v-for="(log,logIndex) in item.user_balance_log" :key="logIndex">
                  <h3>{{ log.reason }}</h3>
                  <h3>{{ log.num }}</h3>
                  <p>{{ log.createtime | dateformat}}</p>
                </van-step>

              </van-steps>
            </div>
          </div>

        </div>
    </mescroll-vue>
    </div>
  </div>
</template>
<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  components: {
    MescrollVue // 注册mescroll组件
  },
  data() {
    return {
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, // 下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        textLoading: 'loading...'
      },
      dataList: [] // 列表数据
    }
  },
  created() {

  },
  methods: {
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 联网请求
      this.$http.post('/invest/web/invest_order_lists', {

        page: page.num, // 页码
        limit: page.size // 每页长度

      }).then((response) => {
        console.log(response)
        // 请求的列表数据
        let arr = response.data.data.list
        // 如果是第一页需手动置空列表
        if (page.num === 1) this.dataList = []
        // 把请求到的数据添加到列表
        this.dataList = this.dataList.concat(arr)
        // 数据渲染成功后,隐藏下拉刷新的状态
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }).catch((e) => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        mescroll.endErr()
      })
    }
  }

}
</script>

<style lang="less" scoped>

.main {
  .card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:10px;
    background-color: #fff;
    margin-bottom: 10px;
    text-align: left!important;;
  }


}
</style>
